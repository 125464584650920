.accordion {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    background-color: #f5f5f5;
  }
  
  .accordion-content {
    padding: 10px;
    border-top: 1px solid #ccc;
    transition: max-height 0.3s ease-in-out;
  }

  .accordion-content.open {
    max-height: 1000px;
  }
  
  .icon {
    width: 10px;
    height: 10px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    transform: rotate(45deg);
    transition: transform 0.3s ease-in-out;
  }
  
  .up {
    transform: rotate(135deg);
  }
  
  .down {
    transform: rotate(-45deg);
  }
  