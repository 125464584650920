.stepper{
  margin-top: 10rem;
}



.FormContent {
    display: flex;
    flex-direction: column;

    max-width: 800px;
    margin: auto;
    margin-top: 4rem;
    margin-bottom: 10rem;

    padding: 2rem;
    box-shadow: rgba(0, 0, 0, 0.40) 0px 3px 8px;
}

.FormTravelType{
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}


.FormTravelType label {
  display: inline-block;
  padding: 8px 16px;
  border: 2px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.FormTravelType label.active {
  background-color: #007bff;
  color: #fff;
}

.FormTravelType input[type="radio"] {
  display: none; /* Masquer le bouton radio natif */
}






.FormLocation{
    display: flex;
    justify-content: center;
    gap: 3rem;
}

.FormDuration{
  display: flex;
    flex-direction: column;
}

.FormLocationDeparture{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}
.FormLocationArrival{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}


.textFieldDeparture{
  width: 100%;
}
.textFieldArrival{
  width: 100%;
}



.FormData{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.FormData input{
    padding: 1rem;
}
.FormData input:hover{
    border: 1px solid #535353;
}
.FormDataDateTime{
    display: flex;
    justify-content: space-between;
    gap: 3rem;
}
.FormDataDate{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.FormDataTime{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.FormDuration .DurationSelect{
  width: 200px;
}
  
  input,
  textarea,
  button {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  
  
  .submitButton {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
  }
  
  .submitButton button:hover {
    background-color: #0056b3;
  }


  /* Large devices (desktops, 992px and less) */
  @media (max-width: 500px) {
    .FormContent{
      box-shadow: none;
    }
    .FormTravelType{
      flex-direction: column;
    }
    .FormLocation{
      flex-direction: column;
    }
  }

  .error {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }
  
  .input-error {
    border: 1px solid red;
  }

  .loader{
    text-align: center;
  }