a{
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    color: azure;
    font-style: normal;
    font-weight: 500;
}

.drawerTelButton{
    margin: auto;
}

.drawer a{
    text-decoration: none;
    color: #060606;
    /*color: rgb(37, 37, 37);*/
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

img{
    margin-top: 12px;
}