.stepper{
    margin-top: 10rem;
  }
  
.FormStep3Content {
  display: flex;
  flex-direction: column;

  max-width: 800px;
  margin: auto;
  margin-top: 4rem;

  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.40) 0px 3px 8px;

  width: 50%;
  }

  .CurrentInformations{
    width: 50%;
    max-width: 800px;
    margin: auto;
    margin-top: 4rem;
    padding: 2rem;
    background-color: #F0F2F7;
    border-radius: 7px;
  }
  
  .price_container_options{
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.40) 0px 3px 8px;
    padding: 20px;
    border-radius: 7px;
    background-color: #fff;
    margin-bottom: 2rem;
  }

  .price_inner_container{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .price_options_inner_container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: baseline;
  }


  .price_container_options p{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .price_container_options .price_content{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
  }

  .price_options_inner_container p{
    font-weight: 500;
    font-size: 15px;
    color: grey;
  }

  .price_options_content{
    font-weight: 500;
    font-size: 15px;
    color: grey;
  }
  
  .estimatedTime_container{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    padding: 2rem 2rem 0 2rem;
  }
  .estimatedTime_container p{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin-top: 0;
  }
  
  .distance_container{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    padding: 0 2rem 0rem 2rem;
  }
  .distance_container p{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin-top: 0;
  }

form{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

input,
  textarea,
  button {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }

  .error {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }
  

  @media (max-width: 500px) {
    .container{
      flex-direction: column-reverse;
    }
    .FormStep3Content{
      width: 95%;
    }
    .CurrentInformations{
      width: 95% !important;
    }
    tr{
      flex-direction: column !important;
      display: flex !important;
    }
    table{
      min-width: unset !important;
    }
    .label_data{
      font-weight: 600 !important;
    }
  }