.stepper{
    margin-top: 10rem;
  }
  

.container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10rem;
}

.FormStep2Content {
      display: flex;
      flex-direction: column;
  
      max-width: 800px;
      margin: auto;
      margin-top: 4rem;
  
      padding: 2rem;
      box-shadow: rgba(0, 0, 0, 0.40) 0px 3px 8px;

      width: 50%;
  }

.CurrentInformations{
  width: 50%;
  max-width: 800px;
  margin: auto;
  margin-top: 4rem;
  padding: 2rem;
  background-color: #F0F2F7;
  border-radius: 7px;
}

.price_container{
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.40) 0px 3px 8px;
  padding: 20px;
  border-radius: 7px;
  background-color: #fff;
  margin-bottom: 2rem;
}
.price_container p{
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0;
}
.price_container .price_content{
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
}

.estimatedTime_container{
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  padding: 2rem 2rem 0 2rem;
}
.estimatedTime_container p{
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin-top: 0;
}

.distance_container{
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  padding: 0 2rem 0rem 2rem;
}
.distance_container p{
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin-top: 0;
}

form{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.travel_item{
  display: flex;
  gap: 5rem;
  margin-left: 1rem;
}


input,
  textarea,
  button {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  button:hover {
    /*background-color: #0056b3;*/
  }

  .navigationButtons{
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .navigationButtons button{
   /*   background-color: #007bff; */
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;
      padding: 10px 20px;
      transition: background-color 0.3s ease;
      width: 100%;
}

.navigationButtons .submitButton{
  background-color: #007bff;
}
.navigationButtons .submitButton:hover{
  background-color: #0056b3;
}

.navigationButtons .previousStepButton{
  background-color: #888888; 
}
.navigationButtons .previousStepButton:hover {
  background-color: #0056b3;
}



  /* Large devices (desktops, 992px and less) */
  @media (max-width: 500px) {
    .container{
      flex-direction: column-reverse;
    }
    .FormStep2Content{
      width: 95%;
    }
    .CurrentInformations{
      width: 95% !important;
    }
    tr{
      flex-direction: column !important;
      display: flex !important;
    }
    table{
      min-width: unset !important;
    }
    .label_data{
      font-weight: 600 !important;
    }
  }