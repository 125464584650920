:root {
  --main-blue-color: #1976d2;
  --background-grey-light: #f0f2f7;
  --background-black: #060606
  /* --main-blue-color: #2272FF; */
}


html {
  scroll-behavior: smooth;
}


.main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /*padding: 6rem; */
    min-height: 100vh;
    background-color: white;
    color: #000;
  }

  h2{
    font-family: "Roboto Slab", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 2.2rem;
    line-height: 1.625;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
  }

  h1{
    font-family: "Roboto Slab", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 2.2rem;
    line-height: 1.625;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
  }

  
  /* HEADER SECTION */

  .header_section{
      height: 750px;
      width: 100%;
      opacity: 1;
      background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url('../../../public/LandingHeaderDark.webp');
      background-repeat: no-repeat;
      background-size: cover;
      color: rgb(52, 71, 103);
      box-shadow: none;
      display: grid;
      place-items: center;
  }
  
  
  .header_section_content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    /*height: 100%;*/
    margin-top: 5%;
    width: 80%;
    margin-bottom: 4rem;
  }

  .header_section_content .title_content{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header_section_content .title_content p{

    margin: 8px 0px 0px;
    font-size: 2.25rem;
    font-weight: 400;
    line-height: 1.625;
    text-align: center;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: var(--main-blue-color);
    letter-spacing: -0.125px;

    font-weight: 400;
    font-style: normal;

  }

  .header_section_content .title_content img{
    width: 420px;
    height: 80px;
  }

  .header_section_content .title_content h1{
    color: #2272ff;
  }

  .header_section_subText{
    font-weight: 400;
    font-style: normal;
    font-size: 1.2rem;
    color: white;
    font-family: "Roboto Slab", sans-serif;
  }
  
  .headerSectionSubText{
    margin-top: -2.5rem;
    text-align: center;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.016em;
    color: white;
  }
  
  .header_section_subText a{
    color: var(--main-blue-color);
    text-decoration: none; 
  }

  .reservation_container{
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    color: white;
    font-size: 20px;
  }

  .reservation_container a{
    color: #fff;
    background-color: #1976d2;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    padding: 1rem;
    border: none;
    font-size: 20px;
    font-weight: 550;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .reservation_container a:hover{
    background-color: #12385f;
  }

  .Pre_form{
    background-color: white;
    padding: 20px;
    width: 500px;
    padding-bottom: 2rem;
    gap: 0px;
    border-radius: 5px;
    color: black;
    font-weight: 700;
  }
  .Pre_form h2{
    text-align: center;
  }
  .Pre_form input{
    width: 100%;
    height: 50px;
    background-color: #F0F2F7;
    border: 0px;
  }
  .Pre_form button{
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
  }
  .Pre_form button:hover{
    background-color: #004085;
  }
  

  /* BODY */

.body_section{
    margin-left: 20px;
    margin-right: 20px;
    width: 100%;

    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 0px;
    overflow-wrap: break-word;
    background-clip: border-box;
    overflow: visible;
    /* padding: 16px; */
    /*margin-bottom: 3rem; */
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: saturate(200%) blur(30px);
    box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
}

/* HEAD TITLE SECTION */

.head_title_section{
  background-image: url('../../../public/mercedes_dark.webp');
  background-position: 100% 50%;


  background-color: black;
  background-repeat: no-repeat;
  background-size: 50% auto;

  width: 100%;
  height: 215px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
    gap: 13rem;
}
.head_title_content{
  display: flex;
  flex-direction: column;
  max-width: 1200px;
 /* margin: auto; */
}

.head_title_section h3{
  color: white;
  font-family: "Roboto Slab", sans-serif;
  font-size: 2.2rem;
  margin-bottom: 0;
}
.head_title_section p{
  margin-top: 0;
  color: white;
}

.reservation_link{
  display: flex;
  gap: 10px;
}

.reservation_link button{
  width: 350px;
  background: linear-gradient(70deg, #2272ff, #125fab);
  color: white;
  height: 55px;
  font-size: 22px;
  font-family: "Roboto Slab", sans-serif;
}

.social_media_icons{
  display: flex;
  gap: 1rem;
}

.social_media_icons img{
  width: 25px;
  color: white;
  fill: white;
}

  /* PRESENTATION SECTION */

.presentation_section {
    width: 100%;
    margin: auto;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  
  .presentation_section_content{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: auto;
    gap: 3rem;
    margin-top: 2rem;
  }

  .presentation_section_content h2{
    /*color: var(--main-blue-color);
    text-align: center; */
  }

  .presentation_section_content img{
    /*-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0
    filter: grayscale(100%); */
    width: 500px;
    height: 350px;
    border-radius: 5px;
  }

  .presentation_text{
    display: flex;
    flex-direction: column;
  }
  
  .presentation_section_content p {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    /*font-family: 'Avenir Next';*/
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.016em;
    
    
  }


  /* NOS SERVICE SECTION */

  .nos_services_container{
    margin-top: 3rem;
    padding-bottom: 4rem;
    background-color: var(--background-grey-light);
  }

  .nos_services_container h2{
   /* color: rgb(52, 71, 103); */
    color: #060606;
    text-align: center;
  }

  .nos_services_content{
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding-top: 2rem;
    gap: 3rem;
  }



  .nos_services_item_content{
    display: flex;
    gap: 1rem;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
  }

  .nos_services_item{
    max-width: 30%;
  }

  .nos_services_item img{
    width: 100%;
    height: 150px;
    border-radius: 8px;
    object-fit: cover;
  }

  

  .nos_services_item{
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .nos_services_item svg{
    width: 20px;
  }


  .nos_services_item_content h4{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 41px;
    letter-spacing: 0.016em;
    color: black;
    margin-bottom: 0;
    margin-top: 1rem;
  }
  
  .nos_services_item_content p{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.016em;
    margin-bottom: 0;
    color: #060606;
    margin-top: 1rem;
  }

  
  .nos_services_item_content a{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.016em;
    margin-bottom: 2rem;
    color: black;
  
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    text-decoration: underline;
  }



  /* RESERVATION SECTION */

  
  .reserver_son_trajet_container{
    max-width: 1200px;
    flex-direction: column;
    margin: auto;
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  
  .reserver_son_trajet_container h2{

    line-height: 1.625;
    text-align: center;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    letter-spacing: -0.125px;
    margin-bottom: 5rem;
  }
  
  .reserver_son_trajet_steps {
    display: flex;
    gap: 3rem;
    justify-content: center;
    padding-top: 2rem;
  }

  .reserver_son_trajet_steps img{
    margin-top: 0;
  }
  
  .card_travel{
    width: 30%;
    background-color: #060606 !important;
    color: white !important;

    box-shadow: rgba(0, 0, 0, 0.8) 0px 3px 20px !important;
  }
  .card_travel div {
    font-size: 1.5rem;
  }
  .card_travel p {
    color: white !important;
    font-size: 18px;
  }
  
.reserver_son_trajet_steps div{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 3rem;
  
    letter-spacing: 0.016em;
  }

  .reserver_son_trajet_steps p{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    letter-spacing: 0.016em;
    font-size: 18px;
    color: rgb(52, 71, 103);
  }







/* CAR SECTION */

.cars_list_section {
  width: 100%;
  /*max-width: 1800px; */
  margin: auto;
  background-color: var(--background-grey-light);
}

.cars_list_container{
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 1500px;
  margin: auto;
}

.cars_list_container h2{
  margin-top: 5rem;
  color: #060606;
  display: flex;
  justify-content: center;
  text-align: center;
}

.cars_list_container p{
  max-width: 90%;
  text-align: center;
}

.cars_list_cards{
  display: flex;
  justify-content: space-evenly;
  margin-top: 6rem;
  margin-bottom: 6rem;
  gap: 4rem;
  max-width: 90%;
  flex-wrap: wrap;
}

.Card{
  background-color: #0a0a0a !important;
 /* border: 1px solid var(--main-blue-color); */
  padding: 3rem;
}
.passengerNb {
  color: white !important;
}
.LuggageNb{
  color: white !important;
}


/* ECO SECTION */

.eco_section {
  height: 500px;
  width: 100%;
  /*max-width: 1800px; */
  margin: auto;
  background-color: var(--background-grey-light);
  background-image: url('../../../public/prius_banner_darken.webp');

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.eco_container{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    max-width: 70%;
    margin: auto;
    text-align: center;
    color: white;
    font-size: 21px;
    font-weight: 600;
    margin-top: 6rem;
}

.eco_title{
  color: #fff !important;
}

.eco_container a{
  text-decoration: underline;
  color: white;
}

.eco_container h2{
  color: #fff;
  font-weight: 700;
  font-style: normal;
  font-size: 2.2rem;
  line-height: 1.625;
  opacity: 1;
}

.eco_card{
  
}

.eco_card img{
  width: 250px;
}




  /* CART MINI SERVICE SECTION */

  .car_inside_services_section {

    width: 100%;
    padding-top: 2rem;
    padding-bottom: 6rem;
    background-color: #060606;
  }
  
  .car_inside_services_container {
    max-width: 1500px;
    display: flex;
    align-items: center;
    margin: auto;
    flex-direction: column;
    gap: 2rem;
  }

  .car_inside_services_container h3{
    font-family: "Roboto Slab", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 2.3rem;

    letter-spacing: 0.016em;
    display: flex;
    justify-content: flex-start;
  
    color: white;
  }
  
  .car_inside_services_content{
    display: flex;
    flex-direction: row;
    gap: 3rem;
  }
  
  .car_inside_services_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .car_inside_services_item svg{
    width: 80px;
    height: 80px;
    color: var(--main-blue-color);;
  }
  
  .car_inside_services_item p {
    text-align: center;
    font-family: "Roboto Slab", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    color: white;
  }

  

    /* FAQ SECTION */

  .Faq_section{
    width: 100%;
    max-width: 1800px;
    margin: auto;
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .Faq_section h3{
    display: flex;
    justify-content: flex-start;
    margin: auto;

    font-family: "Roboto Slab", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 2.3rem;

    letter-spacing: 0.016em;
    display: flex;
    justify-content: flex-start;
  
    color: black;
  }
  
  .Faq_section h4{
    display: flex;
    justify-content: flex-start;
    margin: auto;

    font-family: "Roboto Slab", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1.5rem;

    letter-spacing: 0.016em;
    display: flex;
    justify-content: flex-start;
  
    color: var(--main-blue-color);
  }
  
  .Faq_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    margin: auto;
    gap: 1rem;
  }
  .Faq_content_title{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0;
    margin-bottom: 2rem;
  }
  .Faq_content_text p{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-size: 18px;
  }



/* CONTACT SECITON */

.contact_section{
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url('../../../public/driverBackground.webp');
  background-repeat: no-repeat;
  background-size: cover;
}

.contact_content{
  max-width: 1000px;
  margin: auto;
  margin-top: 6rem;
  margin-bottom: 6rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contact_section button{
    padding: 2rem 4rem 2rem 4rem;
    font-size: 16px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

.contact_content_left{
  padding: 3rem;
  max-width: 500px;
  color: white;
  background: linear-gradient(to bottom right, #1a3b5b, #1976d2);
  border: 1px solid #474747;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact_content_left h2{
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  letter-spacing: 0.016em;
  margin-bottom: 1rem;
  text-align: center;
  color: white;
}

.contact_content_left a{
  margin: auto;
}

.contact_content_left p {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.016em;
}


.reservation_link_section{margin-bottom: 5rem;}
.reservation_link_content{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  max-width: 1200px;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 8px;
  padding: 3rem;
  gap: 2rem;
}
.reservation_link_content p{
    font-size: 18px;
    font-family: "Roboto Slab", sans-serif;
    color: #444444;
    font-weight: 600;
}
.reservation_link_right button{
  width: 350px;
  background: linear-gradient(70deg, #2272ff, #125fab);
  color: white;
  height: 55px;
  font-size: 22px;
  font-family: "Roboto Slab", sans-serif;
}


.reservation-link{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.conseils-list{
  gap: 10px;
  display: flex;
  flex-direction: column;
}


/* CONTACT SECTION */
/*
.contactSection {
  width: 100%;
  max-width: 1800px;
  margin: auto;
}

.contactSectionContent{
  max-width: 1000px;
  flex-direction: column;
  margin: auto;
  margin-bottom: 6rem;
}

.contactSectionContent h2{
  font-family: Roboto, Helvetica, Arial, sans-serif;
  color: rgb(52, 71, 103);
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0.016em;
}

.contactContentForm{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3rem;
}

.contactContentText{
  min-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contactContentText p{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.016em;

  font-family: Roboto, Helvetica, Arial, sans-serif;
  color: rgb(52, 71, 103);
}

.contactContentText img{
  height: 250px;
  width: 100%;
}

*/



/*


.bottomContactSection{
  width: 100%;
  margin: auto;
}

.bottomContactSectionContent{
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-bottom: 6rem;
  margin-top: 6rem;
}

.bottomContactSectionContent h2{
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0.016em;
}

.bottomContactPhoneNb {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-size: 30px;
  font-weight: 600;
  margin-top: 2rem;
}

.bottomContactPhoneDivide{
  border: solid 1px #000;
  width: 15%;
  margin: 1.5rem;
}

.bottomContactPhoneText{
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.016em;
  margin-bottom: 2rem;
}

.bottomContactSection button{
  padding: 2rem 4rem 2rem 4rem;
  font-size: 16px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

*/

  /* Large devices (desktops, 992px and less) */
  @media (max-width: 1000px) {

    h2{
      font-size: 1.8rem;
      max-width: 80%;
      margin: auto;
    }

    /* HEADER SECTION */

    .header_section{
    /*  height: 650px; */
    }

    .header_section_content{
      flex-direction: column;
      gap: 2rem;
    }
    
    .header_section_content .title_content img{
      width: 300px;
      height: 60px;
    }

    .header_section_content .title_content p{
      font-size: 1.5rem;
    }


    .header_section_subText {
      font-size: 1.3rem;
      text-align: center;
      max-width: 80%;
    }


    /* HEAD TITLE SECTION */

    .head_title_section{
      flex-direction: column;
      gap: 3rem;
      height: unset;
      padding-bottom: 2rem;
      background-position: 105% 105%;
    }
    .head_title_content{
      align-items: center;
      
    }

    .head_title_section h3{
      font-size: 2rem;
    }

    .head_title_section p{
      font-size: 1rem;
    }

    .reservation_link{
      flex-direction: column;
    }

    /* PRESENTATION SECTION */

    .presentation_section_content{
      flex-direction: column-reverse;
      gap: 2rem;
    }

    .presentation_text{
      gap: 1.5rem;
    }

    .presentation_text h1{
      text-align: center;
    }

    .presentation_text p {
      font-size: 16px;
      max-width: 85%;
      text-align: center;
      margin: auto;
      gap: 2rem;
    }

    .presentation_section_content img{
      width: 90%;
      height: 140px;
      border-radius: 13px;
      object-fit: cover;
    }


    /* NOS SERVICE SECTION */

    .nos_services_item{
      max-width: 90%;
      margin: auto;
      gap: 1rem;
    }
    .nos_services_item h4{
      font-size: 22px;
    }

    .nos_services_item_content{
      max-width: 95%;
    }

    .nos_services_item_content p{
      font-size: 14px;
    }



    /* RESERVATION SECTION */

    .reserver_son_trajet_container{
      max-width: 90%;
    }


    /* CAR LIST SECTION */

    .cars_list_container h2{
      margin-top: 5rem;
    }

    .cars_list_cards{
      flex-direction: column;
      gap: 1rem;
    }

    .Card{
      padding: 1rem;
    }

    .Card img{
      height: 110px;
    }



    /* CAR INSIDE SERVICES SECTION */

    .car_inside_services_section{
      margin-top: -2px;
    }

    .car_inside_services_container h3{
      max-width: 80%;
      text-align: center;
      font-size: 1.7rem;
    }

    .car_inside_services_content{
      flex-wrap: wrap;
      max-width: 90%;
      justify-content: center;
    }

    .car_inside_services_item svg{
      width: 50px;
      height: 50px;
    }



    /* FAQ SECTION */

    .Faq_section{
      margin-top: 5rem;
      margin-bottom: 5rem;
    }

    .Faq_content_text{
      max-width: 95%;
      margin: auto;
    }

    .Faq_content_title{
      max-width: 90%;
      text-align: center;
      margin: auto;
    }

    .Faq_content_title h3{
      font-size: 2rem;
    }

    .Faq_content_title h4{
      font-size: 1.1rem;
    }


    /* CONTACT SECTION */

    .contact_content{
      flex-direction: column-reverse;
      align-items: center;
      margin-top: 0;
    }



    .reservation_link_content{
      flex-direction: column;
      text-align: center;
    }


}



  /* Large devices (desktops, 992px and less) */
  @media (max-width: 700px) {

    h2{
      font-size: 1.8rem;
      max-width: 80%;
      margin: auto;
    }


    /* HEADER SECTION */

    .header_section{
      /*height: 550px; */
    }

    .Pre_form{
      width: 100%;
    }
    
    .header_section_content .title_content img{
      width: 220px;
      height: 40px;
    }

    .header_section_content .title_content p{
      font-size: 1.3rem;
    }

    .header_section_subText {
      font-size: 1.1rem;
      text-align: center;
      max-width: 80%;
    }

    /* HEAD TITLE SECTION */

    .head_title_content{
      align-items: center;
      max-width: 85%;
      text-align: center;
    }

    .head_title_section h3{
      font-size: 1.7rem;
    }

    .head_title_section p{
      font-size: 0.9rem;
    }

    /* NOS SERVICE SECTION */

    .nos_services_item{
      flex-direction: column;
    }

    .nos_services_item img{
      width: 90%;
      height: 150px;
      object-fit: cover;
    }

    
    /* RESERVATION SECTION */

    .reserver_son_trajet_container{
      margin-top: 4rem;
      margin-bottom: 6rem;
    }
    .reserver_son_trajet_container h2{
      margin-bottom: 2rem;
    }

    .reserver_son_trajet_steps{
      flex-direction: column;
      align-items: center;
    }
    .card_travel {
      width: 100%;
    }
    .card_travel img{
      height: 150px;
    }

    /* CONTACT SECTION */ 

    .contact_content_left{
      max-width: 80%;
    }

  
  }  


    /* Large devices (desktops, 992px and less) */
    @media (max-width: 500px) {

      h2{
        font-size: 1.8rem;
        max-width: 80%;
        margin: auto;
      }
  
      /* HEADER SECTION */
  
      .header_section{
      /*  height: 500px; */
      } 
  
      .header_section_content .title_content img{
        width: 200px;
        height: 40px;
      }
  
      .header_section_content .title_content p{
        font-size: 1.2rem;
      }
  
      .header_section_subText {
        font-size: 1rem;
        text-align: center;
        max-width: 80%;
      }


    /* HEAD TITLE SECTION */


    .head_title_section h3{
      font-size: 1.5rem;
    }


    /* PRESENTATION SECTION */

    .presentation_section {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    /* NOS SERVICE SECTION */

    .nos_services_item_content a{
      justify-content: flex-start;
    }

    /* CONTACT SECTION */ 

    .contact_content_left h2{
      font-size: 20px;
    }

    .contact_content button{
      padding: 1rem 2rem 1rem 2rem;
    }

    .eco_section{
      height: unset;
      padding-bottom: 3rem;
    }

  
  }