.PolitiqueDeConfidentialiteSection{
    margin-top: 7rem;
    margin-bottom: 7rem;
    max-width: 1800px;
}
.PolitiqueDeConfidentialiteSectionContent{
    color: #7c7c7c;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 800px;
    margin: auto;
}

.PolitiqueDeConfidentialiteSectionContent h1{
    text-align: center;
    font-size: 30px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 5rem;
}

.PolitiqueDeConfidentialiteSectionContentText{
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 800px;
    margin: auto;
    gap: 2rem;
}

.PolitiqueDeConfidentialiteSectionContentText ul{
    list-style-type: none;  
}



/* Large devices (desktops, 992px and less) */
@media (max-width: 991.98px) {

    .PolitiqueDeConfidentialiteSectionContent p{
        max-width: 350px;
        text-align: center;
    }
    
}
