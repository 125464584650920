.FormStep4Content {
    display: flex;
    flex-direction: column;
  
    max-width: 800px;
    margin: auto;
    margin-top: 4rem;
  
    padding: 2rem;
    box-shadow: rgba(0, 0, 0, 0.40) 0px 3px 8px;
  
    width: 50%;
    }
    h1{
      text-align: center;
    }
    h2{
      color: #000;
    }

    .price_container_options{
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: space-between;
      box-shadow: rgba(0, 0, 0, 0.40) 0px 3px 8px;
      padding: 20px;
      border-radius: 7px;
      background-color: #fff;
      margin-bottom: 2rem;
    }
  
    .CurrentInformations{
      width: 50%;
      max-width: 800px;
      margin: auto;
      margin-top: 4rem;
      padding: 2rem;
      background-color: #F0F2F7;
      border-radius: 7px;
    }

    .payment_card_container h2{
      font-size: 20px;
      text-align: center;
    }

    .payment_card_online{
      box-shadow: rgba(0, 0, 0, 0.40) 0px 3px 8px;
      border-radius: 10px;
      padding: 2rem;

      display: flex;
      flex-direction: column;
      align-items: center;
      transition: 0.3s;
    }
    .payment_card_online:hover{
      background-color: #deebff;
    }

    .payment_card_incar{
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: rgba(0, 0, 0, 0.40) 0px 3px 8px;
      border-radius: 10px;
      padding: 2rem;
      margin-top: 2rem;
      transition: 0.3s;
    }
    .payment_card_incar:hover{
      background-color: #deebff;
    }

    a{
      width: 100%;
      text-align: center;
    }
    .payment_card_incar img{
      width: 70%;
      height: 120px;
      border-radius: 8px;
      object-fit: cover;
    }

    .navigationButtons{
      margin-top: 2rem;
    }


    .price_inner_container_total {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 1rem;
  }

  .price_content_total{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
  }

  .price_inner_container p{
    font-weight: 500;
    font-size: 15px;
    color: grey;
  }
  .price_content{
    font-weight: 500;
    font-size: 15px !important;
    color: grey;
  }


  @media (max-width: 500px) {
    .FormStep4Content{
      width: 95%;
    }
    .payment_card_online img{
      width: 100% !important;
      height: 100% !important;
    }
    .payment_card_incar img{
      width: 100%;
      height: 70px;
    }
  } 