:root {
    --main-blue-color: #2272FF;
  }

.customCarRangeCard img{
    object-fit: contain;
}

.customCarRangeCard svg{
    width: 20px;
}

.CardType{
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 35px !important;
    line-height: 41px;
    letter-spacing: 0.016em;
    color: white;
    text-align: center;
    margin-bottom: 0;
}

.CardBrand{
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 41px;
    letter-spacing: 0.016em;
    color: var(--main-blue-color);
    text-align: center;
}

.actionButton{
    display: flex;
    justify-content: center;
    color: var(--main-blue-color);
}

.passengerNb{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.LuggageNb{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}


/* Large devices (desktops, 1000px and less) */
@media (max-width: 1000px) {
    .Card{
        max-width: 350px;
    }   

    .CardType{
        font-size: 26px !important;
      }
    .CardBrand{
        font-size: 1rem !important;
    }

    .Card button{
        font-size: 0.7rem;
    }
}

/* Large devices (desktops, 575px and less) */
@media (max-width: 500px) {
    .Card{
        max-width: 350px;
    }   
}