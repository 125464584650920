.eco_content{
    color: black;
    display: flex;
    flex-direction: row;
    gap: 5rem;
    text-align: left;
    margin: auto;
    margin-bottom: 5rem;
}

.eco_text{
    max-width: 800px;
}

.eco_container h2{
    color: black;
    font-family: "Roboto Slab", sans-serif;
}
.eco_container p{
    font-weight: 500;
    font-size: 18px;
    font-family: "Roboto Slab", sans-serif;
}

.eco_container img{
    width: 500px;
    border-radius: 5px;
}

.not_bold{
    font-weight: 500;
}

.eco_container li{
    margin-top: 1rem;
}

.long_img{
    width: 390px !important;
  }




@media (max-width: 1000px) {

.eco_content{
    flex-direction: column;
    text-align: center;
  }
  .eco_content img{
    width: 100%;
  }
  .long_img{
    display: none;
  }

}