.footer{
    width: 100%;
    /*max-width: 1500px;*/
    display: flex;
    flex-direction: column;
    margin: auto;
}

.top{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-color: #060606;
    z-index: 100;
    color: white;
}

.left{
    width: 45%;
}

.left h3{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 70px;
}
.left p {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
}

.right{
    display: flex;
    gap: 4rem;
}

.right h3{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 70px;
}

.right ul{
    list-style: none;
}

.right li{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 30px;
}

.right p{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 30px;
}

.right a{
    text-decoration: none;
    color: white
}

.bottom{
    padding: 1rem;
    text-align: center;

    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    background-color: #000;
    z-index: 100;
    color: azure;
}

.bottom a{
    text-decoration: none;
    color: azure;
}


  /* Large devices (desktops, 992px and less) */
  @media (max-width: 991.98px) {
    .top{
        padding-left: 1rem;
        padding-right: 1rem;
    }
  
  }  

    /* Large devices (desktops, 992px and less) */
    @media (max-width: 700px) {
        .top{
            flex-direction: column;
            align-items: start;
            padding-left: 1rem;
        }
        .left{
            width: 100%;
        }
        .left h3{
            text-align: center;
        }
        .left p{
            text-align: center;
        }
        .right{
            display: flex;
            gap: 4rem;
            width: 100%;
            flex-direction: row;
            justify-content: center;
        }
      
      }  
  
  /* Large devices (desktops, 575px and less) */
  @media (max-width: 575.98px) {
    .top{
        flex-direction: column;
        align-items: start;
        padding-left: 1rem;
    }
    .right{
        flex-direction: column;
    }
  
  
  }