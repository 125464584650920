:root {
    --main-blue-color: #2272FF;
  }

.car_section{
    width: 100%;
    margin: auto;
}

.nos_voitures_section_contentHead{
    background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url('../../../public/mercedes_classeE_wallpapper.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    background-size: cover;
    background-position: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.social_media_icons{
    display: flex;
}
.social_media_icons a{
    width: 40px;
}

.nos_voitures_section_contentHead h1{
    color: white;
    font-size: 3rem;
  }

.CarTitle{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.CarTitle h1{
    text-align: end;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    letter-spacing: 0.016em;
    color: var(--main-blue-color);
}

.CarTitle h2{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    color: var(--main-blue-color);
}

.CarTitleSeperation{
    border: 1px solid var(--main-blue-color);
    width: 50%;
}

.car_details{
    margin: auto;
    gap: 4rem;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 5rem;

    padding-top: 2rem;
    padding-bottom: 2rem;
    flex-direction: column;

    border: 1px solid rgba(168, 168, 168, 0.3);
    background-color: #f0f2f7;
}

.car_details_secondary{
    display: flex;
    margin: auto;
    width: 80%;
    justify-content: space-evenly;
}

.car_details_secondary li{
    font-size: 16px;
}

.CarDetails ul{
    list-style: none;
}

.CarDetails li{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;

    display: flex;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}


.car_details_travel p{
    color: black;
    font-weight: 600;
    align-items: center;
    display: flex;
    gap: 1rem;
}

.car_details_travel h3{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    color: var(--main-blue-color);
}
.car_details_travel ul{
    list-style: none;
}

.car_details_travel li{
    gap: 1rem;
    color: black;
    font-weight: 600;
    align-items: center;
    display: flex;
}
.car_details_travel li svg{
    color: black;
}

.car_details_left{
    margin-left: 5rem;
}
.car_details_left ul{
    list-style: none;
}

.car_details_left h3{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    color: var(--main-blue-color);
}
.car_details_left li{
    color: black;
    font-weight: 600;
}
.car_details_left li svg{
    color: black;
}

.car_details_right h3{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    color: var(--main-blue-color);
}
.car_details_right li{
    color: black;
    font-weight: 600;
}
.car_details_right ul{
    list-style: none;
}
.car_details_right li svg{
    color: black;
}




.car_gallery{
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.car_gallery img{
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 10px;
}

.other_car{
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    align-items: center;
    gap: 3rem;

    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 3rem;
}
.other_car p{
    font-size: 1.5em;
    color: rgb(52, 71, 103);
}

.other_car_button_list{
    display: flex;
    gap: 2rem;
}
.other_car_button_list button{
    padding: 20px;
    font-size: 22px;
}

.contact_section{
    padding-top: 1rem;
    padding-bottom: 1rem;
}


/* Large devices (desktops, 992px and less) */
@media (max-width: 1000px) {

    .nos_voitures_section_contentHead img{
        width: 350px;
    }

    .nos_voitures_section_contentHead h1{
    font-size: 2.5rem;
    }




    .car_gallery{
        flex-wrap: wrap;
    }
    .CarTitleSeperation{
        width: 20%;
    }
    .car_details_secondary{
        flex-wrap: wrap;
        width: 100%;
    }
    .other_car{
        flex-wrap: wrap;
    }

    .CarDetails{
        border: none;
        border-radius: 0;
    }
}

/* Large devices (desktops, 992px and less) */
@media (max-width: 700px) {

    .nos_voitures_section_contentHead h1{
        max-width: 90%;
        text-align: center;
        font-size: 2rem;
    }

    .nos_voitures_section_contentHead img{
    width: 250px;
    }


    .car_details_left{
        margin-left: 0;
    }
    .CarTitle{
        flex-direction: column-reverse;
        max-width: 90%;
        margin: auto;
        text-align: center;
        margin-bottom: 2rem;
    }
}  

/* Large devices (desktops, 992px and less) */
@media (max-width: 500px) {

    .car_details_travel{
        width: 100%;
        margin-left: 1rem;
    }
    .car_details_left{
        width: 100%;
        margin-left: 1rem;
    }
    .car_details_right{
        width: 100%;
        margin-left: 1rem;
    }
    .other_car p{
        max-width: 80%;
        text-align: center;
    }
    .other_carButtonList{
        flex-wrap: wrap;
        justify-content: center;
    }
}







.partie{
    display: flex;
    flex-direction: column;
    width: 1500px;
    margin: auto;
    margin-top: 5rem;
    margin-bottom: 5rem;
    max-width: 90%;
}

.inside{
    display: flex;
    flex-direction: column;
}