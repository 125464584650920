.prestationSectionService{
  width: 100%;
  max-width: 1800px;
  margin: auto;

  margin-top: 10rem;
}

.prestationSectionContentBottom{
  border: 1px solid rgba(168, 168, 168, 0.623);
  margin-top: 3rem;
  padding-bottom: 6rem;
  background-color: #060606;
 /* background-color: #F0F2F7; */
}

.prestationBottom{
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 5rem;
  gap: 3rem;
}
  
  .prestationItem{
    display: flex;
    align-items: center;
    gap: 4rem;
  }
  
  .prestationItem img{
    border-radius: 8px;
  }
  .prestationItem svg{
    width: 20px;
  }
  
  .prestationItemText h4{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 41px;
    letter-spacing: 0.016em;
    color: #2272FF;
    margin-bottom: 1rem;
  }
  
  .prestationItemText p{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.016em;
    margin-bottom: 2rem;
    color: white;
  }
  
  .prestationItemText a{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.016em;
    margin-bottom: 2rem;
    color: #2272FF;
  
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
  }


  .CarMiniServicesSection {
    /*background-color: #F0F2F7;*/
    width: 100%;
    /*max-width: 1800px;*/
    margin: auto;

    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  
  .CarMiniServicesSectionContent {
    max-width: 1000px;
    display: flex;
    align-items: center;
    margin: auto;
    flex-direction: column;
    gap: 4rem;
  }
  
  .CarMiniServicesSectionContent h3{
    font-family: "Luxurious Roman", serif;
    font-weight: 400;
    font-style: normal;

    font-size: 2.3rem;
    letter-spacing: 0.016em;
    display: flex;
    justify-content: flex-start;
  
    color: #2272FF;
  }
  
  .CarMiniServicesSectionContentRight{
    display: flex;
    flex-direction: row;
    gap: 4rem;
  }
  
  .CarMiniServicesSectionItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  
  .CarMiniServicesSectionItem svg{
    width: 80px;
    height: 80px;
    color: #5271FF;
  }
  
  .CarMiniServicesSectionItem p {
    text-align: center;
    font-size: 20px;
    color: white;
  }
  
  .iconCustom{
    color: #16436d;
  }
  
  .FaqSection{
    width: 100%;
    max-width: 1800px;
    margin: auto;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
.FaqSection h3{
  font-family: "Luxurious Roman", serif;
  font-weight: 400;
  font-style: normal;
    font-size: 2.3rem;
    letter-spacing: 0.016em;
    display: flex;
    justify-content: flex-start;
    margin: auto;
  
    color: #5271FF
}

.FaqSection h4{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    letter-spacing: 0.016em;
    display: flex;
    justify-content: flex-start;
    margin: auto;
  
    color: black
}

  .FaqSectionContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    margin: auto;
    gap: 1rem;
  }
  .FaqSectionContentTitle{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0;
    margin-bottom: 2rem;
  }
  .FaqSectionContentText p{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    color: black;
  }

.nos_voitures_section_contentHead p{
  font-size: 20px;
  color: white;
}

.pricing_section {
  padding: 50px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.pricing_section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.pricing_section p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 40px;
  line-height: 1.6;
}

.pricing_container{
    max-width: 1200px;
    margin: auto;
    margin-top: 2rem;
}

.pricing_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

.pricing_card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pricing_card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.pricing_card img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #eee;
}

.pricing_card h3 {
  font-size: 1.5rem;
  margin: 20px 0 10px;
}

.pricing_card p {
  font-size: 1rem;
  color: #555;
  padding: 0 20px;
  margin-bottom: 20px;
}

.pricing_card strong {
  display: block;
  font-size: 1.25rem;
  color: #007bff;
  margin-bottom: 20px;
}

.pricing_advantages {
  text-align: left;
  margin: 0 auto 40px;
  max-width: 800px;
}

.pricing_advantages h3 {
  font-size: 1.75rem;
  margin-bottom: 20px;
}

.pricing_advantages ul {
  list-style: none;
  padding: 0;
}

.pricing_advantages li {
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
  padding-left: 30px;
  position: relative;
}

.pricing_advantages li:before {
  content: "✔";
  color: #007bff;
  font-size: 1.25rem;
  position: absolute;
  left: 0;
  top: 0;
}

.pricing_cta {
  margin-top: 20px;
}

.pricing_cta p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.pricing_cta_button{
  display: flex;
  justify-content: center;
}

.pricing_cta a{
  width: unset;
}

.pricing_cta a,
.pricing_cta .button {
  display: inline-block;
  margin: 0 10px;
}

.pricing_cta a button,
.pricing_cta .button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  height: 55px;
  background: linear-gradient(70deg, #2272ff, #125fab);
}

.pricing_cta a button:hover,
.pricing_cta .button:hover {
  background-color: #0056b3;
}


.hotels_section {
  padding: 50px 20px;
  background-color: #f2f7fc;
  text-align: center;
}

.hotels_container{
  max-width: 1200px;
  margin: auto;
  margin-top: 2rem;
}

.hotels_section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.hotels_section p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 40px;
  line-height: 1.6;
}

.hotels_list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.hotel_item {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.hotel_item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.hotel_item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #eee;
  margin-top: 0;
}

.hotel_item h3 {
  font-size: 1.5rem;
  color: #007bff;
  margin: 20px 0 10px;
}

.hotel_item p {
  font-size: 1rem;
  color: #555;
  padding: 0 20px;
  margin-bottom: 20px;
}

.hotels_section p:last-of-type {
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 40px;
}

.presentation_text h2{
  text-align: center;
}

.eco_container{
  margin-top: 0;
}
.eco_container h2{
  color: white;
}
.eco_container ul{
    display: flex;
    flex-direction: column;
    align-items: baseline;
}
.car_inside_services_container h2,
.car_inside_services_container p{
  color: white;
}
.car_inside_services_container p{
  text-align: center;
}
.contact_content{
  align-items: center;
}

.head_title_content h2{
  color: white;
}

.Faq_content_title h2,
.Faq_content_title p{
  text-align: center;
}

.info_aeroport_section {
  padding: 40px;
  background-color: #f9f9f9;
  margin-bottom: 40px;
}

.section_title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.info_aeroport_container{
  max-width: 1200px;
  margin: auto;
  margin-top: 2rem;
}

.info_aeroport_intro {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.info_aeroport_image {
  width: 50%;
  margin-right: 20px;
  border-radius: 8px;
}

.info_aeroport_text {
  font-size: 1.1rem;
  color: #555;
}

.info_aeroport_details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.info_card {
  width: 30%;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.info_icon {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #007bff;
}

.cta_section {
  text-align: center;
  margin-top: 20px;
}

.cta_button {
  background-color: #007bff;
  color: #fff;
  margin-top: 10px;
  text-transform: uppercase;
}

.roissy_section {
  background-color: #f4f8fb;
}

.orly_section {
  background-color: #fff;
}

.eco_section {
  height: auto;
}
.head_title_section{
  height: auto;
}


  /* =========================================================== */
/* MEDIA QUERY */

/* Large devices (desktops, 992px and less) */
@media (max-width: 991.98px) {

/* Prestation section */
  .prestationSectionContentTop{
    max-width: 800px;
  }
  .prestationSectionContentBottom{
    max-width: 800px;
  }
  .CarMiniServicesSectionContent{
    max-width: 800px;
  }

  .prestationItem{
    width: 85%;
    margin: auto;
  }

}

/* Large devices (desktops, 575px and less) */
@media (max-width: 700px) {

  .prestationItem{
    width: 70%;
  }

  /* prestation section */
  .prestationSectionContentTop h2{
    font-size: 20px;
    line-height: 35px;
  }

  .prestationSectionContentTop h3{
    font-size: 15px;
    justify-content: center;
  }

  .prestationSectionSteps{
    margin: auto;
    gap: 0rem;
    flex-direction: column;
  }

  .prestationItem{ 
    gap: 1rem;
    flex-direction: column;
    margin: auto;
    text-align: center;
  }

  .prestationItemText a{
    margin-bottom: 1rem;
    justify-content: flex-start;
    font-size: 1.5rem;
    gap: 1rem;
  }


  .CarMiniServicesSection{
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .CarMiniServicesSectionContent{
    flex-direction: column;
    max-width: 320px;
    text-align: center;
  }

  .CarMiniServicesSectionContentRight{
    justify-content: center;
    flex-direction: row;
    gap: 1rem;
    margin-top: 1rem;
    flex-wrap: wrap;
  }

  .CarMiniServicesSectionItem{

  }


  .CarMiniServicesSectionItem svg{
    width: 30px;
    height: 30px;
  }



  /* Faq section */
  .FaqSectionContent{
    max-width: 320px;
    margin: auto;
  }


}
