

.erreur404{
    text-align: center;
}
.erreur404 h1 {
    font-size: 9rem;
    margin: 0;
}

.erreur404 p {
    font-size: 2rem;
    margin: 0;
}

.erreur404 a {
    color: #2272FF;
}

.erreur404 img{
    max-width: 1000px;
    width: 90%;
}