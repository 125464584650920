html {
    scroll-behavior: smooth;
  }

.a_propos_section_contentHead{
    background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url('../../../public/suitMan.webp');
    background-repeat: no-repeat;
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: top;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.a_propos_section_contentTop{
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 900px;
    margin: auto;
    gap: 1rem;
    margin-top: 4rem;
}

.a_propos_section_contentTop h2{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    letter-spacing: 0.016em;
    display: flex;
    justify-content: flex-start;
    margin: auto;
  
    color: #5d5d5d;
}
.a_propos_section_contentTop p{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.016em;
    display: flex;
    justify-content: flex-start;
    margin: auto;
  
    color: #5d5d5d;
}

.a_propos_section_contentCoreTop{
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
    gap: 5rem;
}

.a_propos_section_contentCoreTopText{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.016em;
    display: flex;
    justify-content: flex-start;
    margin: auto;
    flex-direction: column;
    gap: 2rem;
  
    color: #5d5d5d;
}


.a_propos_section_contentCoreSentence{
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 900px;
    margin: auto;
    gap: 1rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
}
.a_propos_section_contentCoreSentenceCore{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    letter-spacing: 0.016em;
    display: flex;
    justify-content: flex-start;
    margin: auto;
    flex-direction: column;
    gap: 2rem;
  
    color: #5d5d5d;
}
.a_propos_section_contentCoreSentenceAuthor{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.016em;
    display: flex;
    justify-content: flex-start;
    margin: auto;
    flex-direction: column;
    gap: 2rem;
  
    color: #5d5d5d;
}


.a_propos_section_contentCoreBottom{
    display: flex;
    justify-content: center;
    flex-direction: row;

    max-width: 1200px;
    margin: auto;
    flex-wrap: wrap;
    gap: 2rem;
    margin-bottom: 5rem;
}


.a_propos_section_contentItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    max-width: 300px;
  }

  .a_propos_section_contentItem h3{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    letter-spacing: 0.016em;
  
    color: #5d5d5d;

    text-align: center;
  }
  
  .a_propos_section_contentItem svg{
    width: 60px;
    height: 60px;
    color: #22639F;
  }
  
  .a_propos_section_contentItem p {
    text-align: center;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.016em;
  
    color: #5d5d5d;
  }


/* Large devices (desktops, 992px and less) */
@media (max-width: 991.98px) {

    .a_propos_section_contentTop{
        text-align: center;
        max-width: 570px;
    }

    .a_propos_section_contentCoreTop{
        flex-direction: column;
        text-align: center;
        max-width: 570px;
    }

    .a_propos_section_contentCoreTop img{
        width: 300px;
        height: 200px;
        margin: auto;
    }

    .a_propos_section_contentCoreSentence{
        max-width: 570px;
    }

    .a_propos_section_contentCoreSentenceCore{
        font-size: 20px;
        text-align: center;
    }

}  

  /* Large devices (desktops, 575px and less) */
@media (max-width: 575.98px) {

    .a_propos_section_contentTop{
        text-align: center;
        max-width: 350px;
    }

    .a_propos_section_contentCoreTop{
        flex-direction: column;
        max-width: 350px;
        text-align: center;
        gap: 2rem;
    }

    .a_propos_section_contentCoreTop img{
        width: 300px;
        height: 200px;
        margin: auto;
    }

    .a_propos_section_contentCoreSentence{
        max-width: 350px;
        text-align: center;
    }

    .a_propos_section_contentCoreSentenceCore{
        font-size: 20px;
    }

}