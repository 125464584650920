.travel_container{
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    justify-content: center;
}

.success_paiement_msg{
    text-align: center;
    color: #408944;
    background-color: #EDF7ED;
    padding: 1rem;
    border-radius: 5px;
    font-weight: bold;
}

.travel_form{
    width: 500px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.40) 0px 3px 8px;
    padding: 2rem;
    margin: auto;
}
.travel_form button{
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
    width: 100%;
}

.travel_form button:hover{
    background-color: #0056b3;
}



.Travel_informations{
    width: 500px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.40) 0px 3px 8px;
    padding: 2rem;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: start;
}

.Travel_informations h2{
    text-align: center;
}

.Travel_informations button{
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
    width: 100%;
}

.Travel_informations .cancel_button:hover{
    background-color: #2e2e2e;
}
.Travel_informations .cancel_button{
    background-color: #4b4b4b;
}

.Travel_informations .label_data{
    font-weight: bold;
}

.Travel_informations button:hover{
    background-color: #0056b3;
}

.Travel_informations .cancel_travel_message{
    text-align: center;
    font-size: 20px;
}

.accordion-separator {
    height: 1px;
    background-color: #ccc;
    margin: 10px 0;
  }


@media (max-width: 500px) {
    .Travel_informations{
        width: 95%;
    }
} 
