html {
  scroll-behavior: smooth;
}

:root {
  --main-blue-color: #2272FF;
  --background-grey-light: #f0f2f7;
  --background-black: #060606
}

  /* HEADER SECTION */

.service_aeroport_section_contentHead{
    background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url('../../../public/aéroport-départ.webp');
    background-repeat: no-repeat;
    width: 100%;
    height: 500px;
    background-size: cover;
    background-position: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.service_aeroport_section_contentHead h1{
  color: white;
  font-size: 3rem;
}

.orly{
  background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url('../../../public/orly.webp');
}
.roissy{
  background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url('../../../public/roissy.webp');
}
.van{
  background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url('../../../public/vtc-van.webp');
}
.paris{
  background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url('../../../public/paris-arc-de-triomphe.webp');
}

.link-aéroport{
  display: flex;
  flex-direction: column;
}
.link-aéroport a{
  color: #1461B3;
  text-decoration: underline;
}
.link-aéroport a:hover{
  color: #09294b;
  text-decoration: underline;
}

.service_longues_distance_section_contentHead{
    background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url('../../../public/autoroute.webp');
    background-repeat: no-repeat;
    width: 100%;
    height: 600px;
    background-size: cover;
    background-position: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.service_longues_distance_section_contentHead h1{
  color: white;
  font-size: 3rem;
}

.service_longues_distance_section_contentHead p{
  color: white;
}

.service_mise_a_dispo_section_contentHead{
  background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url('../../../public/driver.webp');
  background-repeat: no-repeat;
  width: 100%;
  height: 600px;
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.service_mise_a_dispo_section_contentHead h1{
  color: white;
  font-size: 3rem;
}


  /* PRESENTATION SECTION */

.service_section_presentation{
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 900px;
    margin: auto;
    gap: 1rem;
}

.service_section_presentation h2{
  font-family: "Luxurious Roman", serif;
  font-weight: 400;
  font-style: normal;
    font-size: 3.5rem;
    letter-spacing: 0.016em;
    display: flex;
    justify-content: flex-start;
    margin: auto;
  
    color: #5d5d5d;
    text-align: center;
}
.service_section_presentation p{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.016em;
    display: flex;
    justify-content: flex-start;
    margin: auto;
    text-align: center;
  
    color: #5d5d5d;
}




  /* DECK SECTION */

.service_content_deck{
    display: flex;
    justify-content: center;
    flex-direction: row;

    padding-top: 2rem;

    margin: auto;
    flex-wrap: wrap;
    gap: 2rem;
    margin-bottom: 5rem;
    align-items: baseline;

    background-color: var(--background-grey-light);
}

.service_deck_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    max-width: 300px;
  }

  .service_deck_item h3{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    letter-spacing: 0.016em;
  
    color: black;

    text-align: center;
  }

  .service_deck_item .title{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    letter-spacing: 0.016em;
    color: black;
    text-align: center;
  }

  
  .service_deck_item svg{
    width: 60px;
    height: 60px;
    color: var(--main-blue-color);
  }
  
  .service_deck_item p {
    text-align: center;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.016em;
  
    color: black;
  }



    /* INFORMATIONS SECTION */

  .service_content_informations{
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
    gap: 3rem;
    align-items: center;
}

.service_content_informations img{
  width: 500px;
  height: 300px;
  border-radius: 8px;
}


.service_content_informations_text{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.016em;
    display: flex;
    justify-content: flex-start;
    margin: auto;
    flex-direction: column;

}



/* ADVERTISING SECTION */


.adverstising_section{
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url('../../../public/driverBackground.webp');
  background-repeat: no-repeat;
  background-size: cover;
}

.adverstising_section_content{
  max-width: 1000px;
  margin: auto;
  padding-top: 6rem;
  padding-bottom: 6rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.adverstising_section_content button{
  padding: 2rem 4rem 2rem 4rem;
  font-size: 16px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.adverstising_section_content_left{
  padding: 3rem;
  max-width: 500px;
  color: white;
  background: linear-gradient(to bottom right, #1a3b5b, #1976d2);
  border: 1px solid #474747;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.adverstising_section_content_left h2{
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  letter-spacing: 0.016em;
  margin-bottom: 1rem;
  text-align: center;
}

.adverstising_section_content_left a{
  margin: auto;
}

.adverstising_section_content_left p {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.016em;
}



.contact_section{
  padding-top: 1rem;
  padding-bottom: 1rem;
}


/* Large devices (desktops, 1000px and less) */
@media (max-width: 1000px) {


  /* HEADER SECTION */

  .service_aeroport_section_contentHead img{
    width: 350px;
  }

  .service_aeroport_section_contentHead h1{
    font-size: 2.5rem;
  }

  .service_longues_distance_section_contentHead img{
    width: 350px;
  }
  .service_longues_distance_section_contentHead h1{
    font-size: 2rem;
    max-width: 90%;
  }

  .service_mise_a_dispo_section_contentHead img{
    width: 350px;
  }
  .service_mise_a_dispo_section_contentHead h1{
    font-size: 2rem;
    max-width: 90%;
  }


  /* DECK SECTION */

  .service_content_deck{
    margin-top: -2px;
  }


  /* INFORMATIONS SECTION */

  .service_content_informations{
    flex-direction: column;
    max-width: 90%;
  }

  .service_content_informations img{
    width: 85%;
    height: 150px;
    object-fit: cover;
  }

  .bottom_infos{
    flex-direction: column-reverse;
  }


  /* ADVERTISEMENT SECTION */

  .adverstising_section_content{
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 0;
  }

  
}

/* Large devices (desktops, 700px and less) */
@media (max-width: 700px) {

  /* HEADER SECTION */

  .service_aeroport_section_contentHead h1{
    max-width: 90%;
    text-align: center;
    font-size: 2rem;
  }

  .service_aeroport_section_contentHead img{
    width: 250px;
  }

  .service_longues_distance_section_contentHead h1{
    max-width: 90%;
    text-align: center;
    font-size: 2rem;
  }

  .service_longues_distance_section_contentHead img{
    width: 250px;
  }

  .service_mise_a_dispo_section_contentHead h1{
    max-width: 90%;
    text-align: center;
    font-size: 2rem;
  }
  .service_mise_a_dispo_section_contentHead img{
    width: 250px;
  }

  /* DECK SECTION */

  .service_deck_item svg{
    width: 45px;
    height: 45px;
  }

  .service_deck_item h3{
    font-size: 1.3rem;
  }
  .service_deck_item p{
    font-size: 17px;
  }

  .service_deck_separator{
    border: 1px solid rgb(90, 90, 90);
    width: 80%;
  }


  /* ADVERTISEMENT SECTION */ 

  .adverstising_section_content_left{
    max-width: 80%;
  }
  
  .adverstising_section_content_right img{
    width: 250px; 
   }
  
}

/* Large devices (desktops, 500px and less) */
@media (max-width: 500px) {

  /* ADVERTISING SECTION */ 

  .adverstising_section_content_left h2{
    font-size: 20px;
  }

  .adverstising_section_content button{
    padding: 1rem 2rem 1rem 2rem;
  }
  
}
